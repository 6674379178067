import { lazy } from 'react';

export const componentLookup = {
  homeAcpBannerV1: lazy(() => import('~/components/base/v1/ACP Banner')),
  homeCompetitorComparisonV1: lazy(() =>
    import('~/components/base/v1/Competitor Comparison')
  ),
  homeEducationModuleV1: lazy(() =>
    import('~/components/base/v1/Education Module')
  ),
  //homeFaqsV1: lazy(() => import('~/components/base/v1/FAQs')),
  homeFinalEpqV1: lazy(() => import('~/components/base/v1/Final EPQ')),
  homeHeroMirV1: lazy(() => import('~/components/base/v1/MIR Hero')),
  homeNavHeaderV1: lazy(() => import('~/components/base/v1/Nav Header')),
  homeUSNewsV1: lazy(() => import('~/components/base/v1/US News')),
  homeHeroFlexSplitV1: lazy(() =>
    import('~/components/base/v1/Hero Flex Split')
  ),
  homeValuePropsV1: lazy(() => import('~/components/base/v1/Value Props')),
  homeJupiterBannerV1: lazy(() =>
    import('~/components/base/v1/Jupiter Banner')
  ),
  homeWholeHomeWifiV1: lazy(() =>
    import('~/components/base/v1/Whole Home WiFi')
  ),
  homeRtbHeroV1: lazy(() => import('~/components/base/v1/RTB Hero')),
  homeOfferHeroV1: lazy(() => import('~/components/base/v1/Offer Hero')),
  homeFusionEduModuleV1: lazy(() =>
    import('~/components/base/v1/Fusion Edu Module')
  ),
  homeHomepageReviewsV1: lazy(() =>
    import('~/components/base/v1/Homepage Reviews')
  ),
  homeInactivityModalV1: lazy(() =>
    import('~/components/base/v1/Inactivity Modal')
  ),
  homeReturnVisitorModalV1: lazy(() =>
    import('~/components/base/v1/Return Visitor Modal')
  ),
  homeSelfServicePortalV1: lazy(() =>
    import('~/components/base/v1/Self Service Portal')
  ),
  homeFaqsV1: lazy(() => import('~/components/base/v1/FAQs')),
  homeHeroStandardSplitV1: lazy(() =>
    import('~/components/base/v1/Hero Standard Split')
  ),
  homeRuralUsNewsV1: lazy(() => import('~/components/base/v1/Rural US News')),
  empty: lazy(() => import('~/components/base/v1/Empty')),
  homeTileV1: lazy(() => import('~/components/base/v1/Tile')),
  homeFooterV1: lazy(() => import('~/components/base/v1/Footer')),
};
