import React from 'react';

export const Check = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      {...props}
    >
      <rect width='32' height='32' rx='16' fill='#DE3700' />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M23.4142 12.4142L14 21.8285L8.58578 16.4142L11.4142 13.5858L14 16.1716L20.5858 9.58582L23.4142 12.4142Z'
        fill='white'
      />
    </svg>
  );
};
