import React from 'react';

export const XCircle = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM5.36612 7.13388L8.23223 10L5.36612 12.8661L7.13388 14.6339L10 11.7678L12.8661 14.6339L14.6339 12.8661L11.7678 10L14.6339 7.13388L12.8661 5.36612L10 8.23223L7.13388 5.36612L5.36612 7.13388Z'
        fill='#53565A'
      />
    </svg>
  );
};
