import React, { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import styles from './Nav.module.scss';
import { HughesnetLogoBrand } from '~/components/icons';
import { AccountPersonBrand } from '~/components/icons/AccountPersonBrand';
import AddressForm from './components/AddressForm';
import { useMedia } from '~/helpers/hooks';
import HeaderCTA from './components/HeaderCTA';
import { MonarchSlot } from '@redventures/cohesion-utils-react';
import { getFilteredComponentName } from '~/helpers/utils/getFilteredComponentName';
import { useMonarchSlotContext } from '@redventures/cohesion-utils-react';

const BASE_DATA = {
  addressCTACopy: 'Shop plans',
  addressLabelColor: 'white',
  addressLabelCopy: 'Enter your address to check available plans',
  addressPlaceHolderCopy: 'Enter your address',
  bottomNav: false,
  callCTA: true,
  callCTABorderColor: '#154b8e',
  callCTAColor: '#154b8e',
  callCTACopy: 'CALL NOW',
  callCTAFontColor: '#ffffff',
  mapPinIconColor: '#154B8E',
  mobileAddressCTACopy: 'Go',
  mobileCallCTACopy: 'CALL NOW',
  showAddress: true,
};

function NavHeader() {
  const { data: monarchData } = useMonarchSlotContext();
  const router = useRouter();
  const isMedia = useMedia();
  const handleTracking = (tracking) => {
    tagular('beam', 'ElementClicked', {
      '@type': 'redventures.usertracking.v3.ElementClicked',
      webElement: {
        location: tracking.location || '',
        position: tracking.position || '',
        elementType: tracking.elementType || '',
        text: tracking.text || '',
        htmlId: tracking.htmlId || '',
      },
      actionOutcome: tracking.actionOutcome || '',
    });
  };
  const data = { ...BASE_DATA, ...monarchData };
  const { bottomNav, showAddress } = data;

  const mozilla = navigator.userAgent.includes('Mozilla');

  const [isVisible, setIsVisible] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(true);

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);

  const getOffset = (element, isHero) => {
    const rect = element?.getBoundingClientRect();
    //Adding the offset for the final EPQ since it's showing the header too early
    const offset = isHero
      ? rect?.bottom
      : isMedia.mobile
      ? rect?.bottom + 2 * rect?.y + rect?.height
      : rect?.bottom + rect?.y + rect?.height;

    return offset;
  };

  const listenToScroll = () => {
    //Check if the modal is in the DOM
    const modalElement = document.getElementById('modal-redesign');
    if (typeof modalElement != 'undefined' && modalElement != null) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
    //Scroll it takes to get to the bottom of each element using the offset
    const heightToHideFrom1 = getOffset(document.getElementById('hero'), true);
    const heightToHideFrom2 = getOffset(
      document.getElementById('finalEpq'),
      false
    );
    //Current scroll of the user
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    //I'm not proud of this Hacky 101: Scrolling distance in which the header starts flickering in relation to the scroll top and the offset to the final epq
    if (winScroll > 2700 && winScroll < 2900) return;

    if (winScroll >= heightToHideFrom1 && winScroll <= heightToHideFrom2) {
      //Make Nav EPQ appear
      setIsVisible(true);
      //Delay when the form should show after the transition starts
      setTimeout(() => {
        setShowForm(true);
      }, 350);
    } else {
      //Make Nav EPQ disappear
      setIsVisible(false);
      //Delay when the form should disappear after the transition starts
      setTimeout(() => {
        setShowForm(false);
      }, 350);
    }
  };

  return (
    <div className={styles.headerContainer}>
      {/* Banner above header placement */}
      {router.route === '/' && (
        <MonarchSlot
          placement='3e3izmeXoGOE53IEXLkR4k'
          componentId={getFilteredComponentName}
        />
      )}
      <header className={styles.header}>
        <div className={styles.container}>
          <div className={styles.leftNav}>
            <Link
              prefetch={false}
              href='/'
              passHref
              className={styles.logoBlue}
              aria-label='Hughesnet Logo'
            >
              <HughesnetLogoBrand
                id='1'
                className={styles.logoBlue}
                onClick={() =>
                  handleTracking({
                    location: 'NAV',
                    elementType: 'HN LOGO',
                    text: 'Hughesnet',
                    htmlId: 'NAV_HN_LOGO',
                    actionOutcome: 'internal link',
                  })
                }
              />
            </Link>
          </div>

          <div className={styles.secondaryNavLinks}>
            <span>
              <AccountPersonBrand />
            </span>{' '}
            <a
              href='https://my.hughesnet.com/en/overview?utm_medium=PPC2_header'
              className={styles.secondaryNavLinks}
              target='_blank'
              rel='noreferrer'
              onClick={() =>
                handleTracking({
                  location: 'NAV',
                  elementType: 'LINK',
                  text: 'My Account',
                  htmlId: 'NAV_MY_ACCOUNT',
                  actionOutcome: 'external link',
                })
              }
            >
              MY ACCOUNT
            </a>
          </div>
          {/* Animated CTA Placement */}
          <MonarchSlot
            placement='3FU3sb63vgm4Lg5KRF8YZV'
            componentId={getFilteredComponentName}
          >
            <HeaderCTA metadata={data} />
          </MonarchSlot>
        </div>
      </header>
      {showAddress && (
        <div
          className={`${
            isMedia.mobile
              ? bottomNav
                ? styles.bottomNav
                : styles.topNav
              : styles.topNav
          } ${styles.addressWrapper} ${
            isVisible && !isModalVisible && styles.show
          } ${mozilla && styles.mozilla} `}
        >
          {showForm && <AddressForm metadata={data} isNav={true} />}
        </div>
      )}
    </div>
  );
}

NavHeader.propTypes = {};

export default NavHeader;
