import React, { useContext } from 'react';
import LogRocket from 'logrocket';
import classNames from 'classnames';
import styles from './Button.module.scss';
import Link from 'next/link';
import { FuseContext } from '~/providers/FuseProvider';

export default function Button({
  isSecondary,
  isOrange,
  isDisabled,
  className,
  children,
  href,
  fuse,
  tracking,
}) {
  const { fuseNumber } = useContext(FuseContext);

  const onButtonClick = () => {
    if (tracking) {
      LogRocket.track('ElementClicked', tracking);
      tagular('beam', 'ElementClicked', {
        '@type': 'redventures.usertracking.v3.ElementClicked',
        webElement: {
          location: tracking.location || '',
          position: tracking.position || '',
          elementType: tracking.elementType || '',
          text: tracking.text || '',
          htmlId: tracking.htmlId || '',
        },
        actionOutcome: tracking.actionOutcome || '',
      });
    }
    return null;
  };

  if (href) {
    return (
      <Link passHref href={href} className={styles.linkWrapper}>
        <button
          type='button'
          onClick={onButtonClick}
          className={classNames([styles['btn']], className, {
            [styles.secondary]: isSecondary,
            [styles.orange]: isOrange,
            [styles.disabled]: isDisabled,
          })}
        >
          {children}
        </button>
      </Link>
    );
  }
  if (fuse) {
    return (
      <Link href={`tel:${fuseNumber}`} passHref className={styles.linkWrapper}>
        <button
          type='button'
          onClick={onButtonClick}
          className={classNames([styles['btn']], className, {
            [styles.secondary]: isSecondary,
            [styles.orange]: isOrange,
            [styles.disabled]: isDisabled,
          })}
        >
          {children}
        </button>
      </Link>
    );
  }
  return (
    <button
      disabled={isDisabled}
      type='submit'
      onClick={onButtonClick}
      className={classNames([styles['btn']], className, {
        [styles.secondary]: isSecondary,
        [styles.orange]: isOrange,
        [styles.disabled]: isDisabled,
      })}
    >
      {children}
    </button>
  );
}
