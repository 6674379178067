import React from 'react';

export const AppStore = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='121'
      height='41'
      viewBox='0 0 121 41'
      fill='none'
      {...props}
    >
      <rect
        x='1.1001'
        y='1.16455'
        width='119'
        height='39'
        rx='6.5'
        fill='white'
      />
      <rect
        x='1.1001'
        y='1.16455'
        width='119'
        height='39'
        rx='6.5'
        stroke='black'
      />
      <path
        d='M82.126 19.8654V22.1564H80.69V23.6589H82.126V28.7638C82.126 30.507 82.9146 31.2043 84.8985 31.2043C85.2471 31.2043 85.5792 31.1628 85.8697 31.113V29.6271C85.6207 29.652 85.4629 29.6686 85.189 29.6686C84.3008 29.6686 83.9107 29.2536 83.9107 28.3073V23.6589H85.8697V22.1564H83.9107V19.8654H82.126Z'
        fill='black'
      />
      <path
        d='M90.9235 31.3288C93.5632 31.3288 95.1818 29.5607 95.1818 26.6305C95.1818 23.717 93.5549 21.9406 90.9235 21.9406C88.2839 21.9406 86.6569 23.717 86.6569 26.6305C86.6569 29.5607 88.2756 31.3288 90.9235 31.3288ZM90.9235 29.7433C89.3713 29.7433 88.4997 28.6061 88.4997 26.6305C88.4997 24.6716 89.3713 23.5261 90.9235 23.5261C92.4675 23.5261 93.3474 24.6716 93.3474 26.6305C93.3474 28.5978 92.4675 29.7433 90.9235 29.7433Z'
        fill='black'
      />
      <path
        d='M96.5667 31.1545H98.3514V25.8171C98.3514 24.5471 99.306 23.6921 100.659 23.6921C100.974 23.6921 101.506 23.7502 101.655 23.8V22.0402C101.464 21.9904 101.124 21.9655 100.858 21.9655C99.6795 21.9655 98.6751 22.613 98.4178 23.5012H98.285V22.1149H96.5667V31.1545Z'
        fill='black'
      />
      <path
        d='M106.086 23.4596C107.406 23.4596 108.27 24.381 108.311 25.8005H103.746C103.845 24.3893 104.767 23.4596 106.086 23.4596ZM108.303 28.714C107.971 29.4196 107.232 29.8097 106.153 29.8097C104.725 29.8097 103.804 28.8054 103.746 27.2199V27.1203H110.129V26.4977C110.129 23.6589 108.61 21.9406 106.095 21.9406C103.546 21.9406 101.928 23.7751 101.928 26.6638C101.928 29.5524 103.513 31.3288 106.103 31.3288C108.17 31.3288 109.614 30.3327 110.021 28.714H108.303Z'
        fill='black'
      />
      <path
        d='M70.4224 27.8163C70.5601 30.036 72.4099 31.4556 75.163 31.4556C78.1054 31.4556 79.9465 29.9672 79.9465 27.5926C79.9465 25.7257 78.8969 24.6932 76.3503 24.0996L74.9823 23.7641C73.3648 23.3855 72.711 22.8779 72.711 21.9917C72.711 20.8733 73.7262 20.142 75.249 20.142C76.6944 20.142 77.6924 20.8561 77.8731 22.0003H79.7487C79.6368 19.9097 77.7957 18.4385 75.2748 18.4385C72.5647 18.4385 70.758 19.9097 70.758 22.1208C70.758 23.9447 71.7818 25.0288 74.0273 25.5536L75.6276 25.9407C77.2708 26.3279 77.9935 26.8957 77.9935 27.8421C77.9935 28.9434 76.8579 29.7435 75.3092 29.7435C73.6488 29.7435 72.4959 28.995 72.3324 27.8163H70.4224Z'
        fill='black'
      />
      <path
        d='M51.9351 21.9655C50.7066 21.9655 49.6441 22.5798 49.0962 23.6091H48.9634V22.1149H47.2452V34.1594H49.0298V29.7848H49.1709C49.6441 30.7394 50.6651 31.3039 51.9517 31.3039C54.2344 31.3039 55.6871 29.5026 55.6871 26.6305C55.6871 23.7585 54.2344 21.9655 51.9351 21.9655ZM51.4288 29.7018C49.9346 29.7018 48.9966 28.5231 48.9966 26.6388C48.9966 24.7463 49.9346 23.5676 51.4371 23.5676C52.9478 23.5676 53.8526 24.7214 53.8526 26.6305C53.8526 28.548 52.9478 29.7018 51.4288 29.7018Z'
        fill='black'
      />
      <path
        d='M61.9319 21.9655C60.7034 21.9655 59.6409 22.5798 59.093 23.6091H58.9602V22.1149H57.242V34.1594H59.0266V29.7848H59.1677C59.6409 30.7394 60.6619 31.3039 61.9485 31.3039C64.2312 31.3039 65.6839 29.5026 65.6839 26.6305C65.6839 23.7585 64.2312 21.9655 61.9319 21.9655ZM61.4256 29.7018C59.9314 29.7018 58.9934 28.5231 58.9934 26.6388C58.9934 24.7463 59.9314 23.5676 61.4339 23.5676C62.9446 23.5676 63.8494 24.7214 63.8494 26.6305C63.8494 28.548 62.9446 29.7018 61.4256 29.7018Z'
        fill='black'
      />
      <path
        d='M44.0432 31.1545H46.0908L41.6084 18.7396H39.5349L35.0525 31.1545H37.0313L38.1756 27.8593H42.9075L44.0432 31.1545ZM40.4727 20.9937H40.619L42.4171 26.2419H38.666L40.4727 20.9937Z'
        fill='black'
      />
      <path
        d='M36.2512 9.37549V15.3645H38.4136C40.1982 15.3645 41.2317 14.2646 41.2317 12.3513C41.2317 10.467 40.1899 9.37549 38.4136 9.37549H36.2512ZM37.1809 10.2222H38.3098C39.5508 10.2222 40.2854 11.0107 40.2854 12.3638C40.2854 13.7375 39.5632 14.5178 38.3098 14.5178H37.1809V10.2222Z'
        fill='black'
      />
      <path
        d='M44.3968 15.4517C45.7166 15.4517 46.5259 14.5676 46.5259 13.1025C46.5259 11.6458 45.7125 10.7576 44.3968 10.7576C43.077 10.7576 42.2635 11.6458 42.2635 13.1025C42.2635 14.5676 43.0728 15.4517 44.3968 15.4517ZM44.3968 14.6589C43.6207 14.6589 43.1849 14.0903 43.1849 13.1025C43.1849 12.123 43.6207 11.5503 44.3968 11.5503C45.1687 11.5503 45.6087 12.123 45.6087 13.1025C45.6087 14.0862 45.1687 14.6589 44.3968 14.6589Z'
        fill='black'
      />
      <path
        d='M53.4181 10.8447H52.5257L51.7206 14.2937H51.65L50.7203 10.8447H49.8653L48.9356 14.2937H48.8692L48.0599 10.8447H47.1551L48.4002 15.3645H49.3175L50.2472 12.0359H50.3177L51.2516 15.3645H52.1771L53.4181 10.8447Z'
        fill='black'
      />
      <path
        d='M54.4457 15.3645H55.338V12.7207C55.338 12.0151 55.7572 11.5752 56.4171 11.5752C57.077 11.5752 57.3925 11.9363 57.3925 12.6626V15.3645H58.2848V12.4385C58.2848 11.3635 57.7287 10.7576 56.7201 10.7576C56.0395 10.7576 55.5912 11.0605 55.3712 11.5627H55.3048V10.8447H54.4457V15.3645Z'
        fill='black'
      />
      <path
        d='M59.6901 15.3645H60.5825V9.08081H59.6901V15.3645Z'
        fill='black'
      />
      <path
        d='M63.9385 15.4517C65.2583 15.4517 66.0676 14.5676 66.0676 13.1025C66.0676 11.6458 65.2542 10.7576 63.9385 10.7576C62.6187 10.7576 61.8052 11.6458 61.8052 13.1025C61.8052 14.5676 62.6145 15.4517 63.9385 15.4517ZM63.9385 14.6589C63.1624 14.6589 62.7266 14.0903 62.7266 13.1025C62.7266 12.123 63.1624 11.5503 63.9385 11.5503C64.7104 11.5503 65.1504 12.123 65.1504 13.1025C65.1504 14.0862 64.7104 14.6589 63.9385 14.6589Z'
        fill='black'
      />
      <path
        d='M68.7264 14.688C68.2408 14.688 67.888 14.4514 67.888 14.0447C67.888 13.6462 68.1702 13.4346 68.7928 13.3931L69.8968 13.3225V13.7002C69.8968 14.2605 69.3987 14.688 68.7264 14.688ZM68.4981 15.4392C69.0916 15.4392 69.5855 15.1819 69.8553 14.7295H69.9258V15.3645H70.785V12.2766C70.785 11.322 70.1458 10.7576 69.0127 10.7576C67.9876 10.7576 67.2571 11.2556 67.1658 12.0317H68.0291C68.1287 11.7122 68.4732 11.5295 68.9712 11.5295C69.5813 11.5295 69.8968 11.7993 69.8968 12.2766V12.6667L68.6724 12.7373C67.5975 12.8037 66.9915 13.2727 66.9915 14.0862C66.9915 14.9121 67.6265 15.4392 68.4981 15.4392Z'
        fill='black'
      />
      <path
        d='M73.8131 15.4392C74.4356 15.4392 74.9627 15.1445 75.2325 14.6506H75.3031V15.3645H76.1581V9.08081H75.2657V11.5627H75.1993C74.9544 11.0647 74.4315 10.77 73.8131 10.77C72.6717 10.77 71.9371 11.6748 71.9371 13.1025C71.9371 14.5344 72.6634 15.4392 73.8131 15.4392ZM74.0663 11.571C74.8133 11.571 75.2823 12.1646 75.2823 13.1067C75.2823 14.053 74.8175 14.6382 74.0663 14.6382C73.3109 14.6382 72.8585 14.0613 72.8585 13.1025C72.8585 12.1521 73.315 11.571 74.0663 11.571Z'
        fill='black'
      />
      <path
        d='M81.9445 15.4517C83.2644 15.4517 84.0737 14.5676 84.0737 13.1025C84.0737 11.6458 83.2602 10.7576 81.9445 10.7576C80.6247 10.7576 79.8112 11.6458 79.8112 13.1025C79.8112 14.5676 80.6206 15.4517 81.9445 15.4517ZM81.9445 14.6589C81.1684 14.6589 80.7326 14.0903 80.7326 13.1025C80.7326 12.123 81.1684 11.5503 81.9445 11.5503C82.7165 11.5503 83.1564 12.123 83.1564 13.1025C83.1564 14.0862 82.7165 14.6589 81.9445 14.6589Z'
        fill='black'
      />
      <path
        d='M85.2549 15.3645H86.1472V12.7207C86.1472 12.0151 86.5664 11.5752 87.2263 11.5752C87.8862 11.5752 88.2017 11.9363 88.2017 12.6626V15.3645H89.094V12.4385C89.094 11.3635 88.5378 10.7576 87.5293 10.7576C86.8486 10.7576 86.4004 11.0605 86.1804 11.5627H86.114V10.8447H85.2549V15.3645Z'
        fill='black'
      />
      <path
        d='M93.2037 9.71997V10.8655H92.4857V11.6167H93.2037V14.1692C93.2037 15.0408 93.598 15.3894 94.5899 15.3894C94.7643 15.3894 94.9303 15.3687 95.0755 15.3438V14.6008C94.951 14.6133 94.8722 14.6216 94.7352 14.6216C94.2911 14.6216 94.096 14.4141 94.096 13.9409V11.6167H95.0755V10.8655H94.096V9.71997H93.2037Z'
        fill='black'
      />
      <path
        d='M96.2733 15.3645H97.1657V12.7249C97.1657 12.04 97.5724 11.5793 98.3029 11.5793C98.9337 11.5793 99.2699 11.9446 99.2699 12.6667V15.3645H100.162V12.4468C100.162 11.3718 99.5687 10.7617 98.6059 10.7617C97.9252 10.7617 97.4479 11.0647 97.2279 11.571H97.1574V9.08081H96.2733V15.3645Z'
        fill='black'
      />
      <path
        d='M103.381 11.5171C104.041 11.5171 104.473 11.9778 104.494 12.6875H102.211C102.261 11.9819 102.721 11.5171 103.381 11.5171ZM104.489 14.1443C104.323 14.4971 103.954 14.6921 103.414 14.6921C102.701 14.6921 102.24 14.1899 102.211 13.3972V13.3474H105.403V13.0361C105.403 11.6167 104.643 10.7576 103.385 10.7576C102.111 10.7576 101.302 11.6748 101.302 13.1191C101.302 14.5635 102.095 15.4517 103.39 15.4517C104.423 15.4517 105.145 14.9536 105.349 14.1443H104.489Z'
        fill='black'
      />
      <path
        d='M25.3691 20.9654C25.3907 19.2844 26.2935 17.6938 27.7257 16.8133C26.8222 15.523 25.3089 14.7049 23.7345 14.6556C22.0552 14.4794 20.4273 15.6605 19.5716 15.6605C18.6993 15.6605 17.3818 14.6731 15.9631 14.7023C14.1138 14.7621 12.3899 15.8134 11.4902 17.4302C9.55617 20.7786 10.9988 25.6997 12.8514 28.4063C13.7783 29.7316 14.8615 31.2121 16.279 31.1596C17.6661 31.102 18.1841 30.2751 19.8584 30.2751C21.5172 30.2751 22.0032 31.1596 23.4494 31.1262C24.9378 31.102 25.8755 29.7949 26.7699 28.457C27.4359 27.5127 27.9484 26.469 28.2883 25.3645C26.5392 24.6247 25.3711 22.8645 25.3691 20.9654Z'
        fill='black'
      />
      <path
        d='M22.6374 12.8756C23.449 11.9014 23.8488 10.6492 23.7519 9.38501C22.5121 9.51523 21.3669 10.1078 20.5444 11.0446C19.7401 11.96 19.3215 13.1901 19.4007 14.406C20.6409 14.4188 21.8602 13.8423 22.6374 12.8756Z'
        fill='black'
      />
    </svg>
  );
};

AppStore.displayName = 'AppStore';
